import React from "react";
import styled from "styled-components";
import "normalize.css";
// import Nav from './Nav';
import Footer from './Footer';
import GlobalStyles from "../styles/GlobalStyles";
// import Typography from '../styles/Typography';

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyles />
      {children}
      <Footer />
      {/* <GlobalStyles />
        <Typography />      
 
            <Nav />
            {children}
            <Footer /> */}
    </>
  );
}
