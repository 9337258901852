import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  
/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

  *,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}


body {
  background: #fefefe;
  color: #151515;
  line-height: 1.6;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: normal;
  margin: 0;
}

.main-header {
    margin-top: 1em; 
}

.center {
  text-align: center;
}

.underline {
  text-decoration: underline;
}

.bold {
  font-weight: bold; 
}

p, li {
  letter-spacing: 0.5px;
  margin: 0; 
}

li {
  margin-top: 0.5em;
}

h1 {
    font-size: 1.5rem; 
    padding-left: 0.9em;

}

h2 {
  font-size: 1.5rem;
  margin-bottom: 1em;
  padding-left: 0.9em;
}

h3 {
  font-size: 1.4rem;
  margin-bottom: 1.5em;
  padding-left: 0.9em;
}

.info-icon {
  cursor: pointer;
  margin-left: 10px; 
}

.margin-top {
  margin-top: 1em;
}
.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

section, footer {
    max-width: 980px; 
    margin: 0 auto; 
    padding-top: 0.5em; 
    padding-left: 1.75em;
    padding-right: 1.75em;
    margin-bottom: 2em;
}

footer {
  margin-bottom: 0;
}

.margin-bottom {
  margin-bottom: 1em;
}
  

.four-oh-four-text {
  text-align: center;
  font-size: 1.2rem; 
  margin-top: 3em; 
}

.chart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.chart p {
  letter-spacing: unset;
}

.margin-left {
  margin-left: 10px;
}

.diagonal-down {
  margin-left: 65%;
  margin-top: -10%;
}

.transformational {
  margin-top: -65%;
  margin-left: 10%;
}

.small-down-1 {
  display: block;
  margin-top: 10px;
  margin-left: 80px;
}

.chart-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.chart-bottom p {
  max-width: 100px;
  margin-top: -20%;
}

.chart-bottom p:first-child {
  margin-left: 15%;
}

.post-phrase-marker-arrow {
  margin-left: -18px;
  margin-right: 10px;
}

.desktop-top-diagonal, .final-left, .final-right, .interpretation, .triple-arrow-desktop {
  display: none; 
}
.final-left, .final-right, .interpretation {
  display: unset;
}

.final-left {
  margin-left: 25%;
  margin-top: -15%;
}

.final-right {
  margin-top: -15%;
  margin-left: 10%;
}

.interpretation {
  margin-left: 25%;
  margin-top: -10%;
}

#TooltipFive {
  margin-top: -20%;
}

/* Media Queries */

@media (min-width: 445px) {
  .transformational {
    margin-top: -55%;
  }

  .post-phrase-marker-arrow {
    margin-left: 0;
    margin-right: 0;
  }
  .final-left {
  margin-left: 25%;
  margin-top: -10%;
}

.final-right {
  margin-top: -10%;
  margin-left: 10%;
}

.interpretation {
  margin-left: 25%;
  margin-top: -10%;
}
}

@media (min-width: 505px) {
  .diagonal-down {
    margin-left: 70%;
    margin-top: -5%;
  }
  .transformational {
    margin-top: -45%;
    margin-left: 15%;
  }

  .chart-bottom p {
    margin-top: -12%;
  }

  .chart-bottom p:first-child {
    margin-left: 22%;
  }
  .chart-top {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
    .final-left {
    margin-left: 30%;
  }

  .final-right {
    margin-left: 20%;
  }

  .interpretation {
    margin-left: 35%;
    margin-top: -10%;
  }


}


@media (min-width: 540px) {
  .margin-right {
    margin-right: 10px;
  }
}

@media (min-width: 578px) {
  p, li {
    font-size: 1.2rem; 
  }
  .chart-bottom p {
    max-width: 150px;
  }

}

  @media (min-width: 600px) {
  
    h1 {
      font-size: 2.5rem; 
      padding-left: 0.6em;
    }

    h2 {
      font-size: 2rem; 
      
    }
    h3 {
      font-size: 1.8rem;
    }

    .info-icon {
      font-size: 2.5rem; 
    }

    .additional-info-container {
      margin-left: auto;
      margin-right: auto;
    }

    .additional-info {
      font-size: 1rem; 
    }

  }

  @media (min-width: 620px) {

    .transformational {
      margin-top: -35%;
      margin-left: 25%;
    }

    .chart-bottom p:first-child {
      margin-left: 28%;
    }
}

@media (min-width: 640px) {
  .final-left {
    margin-left: 35%;
    margin-top: -8%;
  }

  .final-right {
    margin-left: 20%;
    margin-top: -8%;
  }

  .interpretation {
    margin-left: 40%;
    margin-top: -8%;
  }
}

  @media (min-width: 730px) {

    .transformational {
      margin-top: -25%;
      margin-left: 35%;
    }

    .chart-bottom p:first-child {
      margin-left: 38%;
      margin-top: -6%;
    }

    .diagonal-down {
      margin-left: 77%;
   
    }

  .final-left {
    margin-left: 45%;
    margin-top: -6%;
  }

  .final-right {
    margin-left: 15%;
    margin-top: -6%;
  }

  .interpretation {
    margin-left: 50%;
    margin-top: -6%;
  }
  
   #TooltipFive {
    font-size: 0.9rem;
  }
}

 @media (min-width: 820px) {
    .transformational {
      margin-left: 45%;
    }

    .chart-bottom p:first-child {
      margin-left: 48%;
    }

    .final-left {
      margin-left: 55%;
      margin-top: -6%;
    }

    .final-right {
      margin-left: 10%;
      margin-top: -6%;
    }

    .interpretation {
      margin-left: 55%;
      margin-top: -6%;
    }
}

 @media (min-width: 887px) {
   .chart-bottom p {
    margin-top: -9%;
  }
}

 @media (min-width: 905px) {
    .diagonal-down {
      margin-left: 80%;
    }
    
    h1, h2, h3 {
      text-align: center;
    }

}

 @media (min-width: 970px) {
  .transformational {
    margin-left: 55%;
    margin-top: -22%;
  }

  .chart-bottom p:first-child {
    margin-left: 57%;
  }
  
  .final-left {
    margin-left: 63%;
    margin-top: -5%;
  }

  .final-right {
    margin-left: 8%;
    margin-top: -5%;
  }

  .interpretation {
    margin-left: 63%;
    margin-top: -6%;
  }
}

@media (min-width: 1200px) {
  .diagonal-down {
    display: none;
  }

  .desktop-top-diagonal {
    display: unset;
    margin-left: 54%; 
    margin-top: -4%;
  }

  .transformational {
    margin-left: 80%;
    margin-top: -25%;
  }

  .phonetic {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }

  .semantic {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    margin-left: 45%
  }

  .chart-bottom p:first-child {
    margin-top: -5%;
    margin-left: 13%;
  }

  .chart-bottom p {
    margin-top: -6%;
    max-width: 350px;
  }

  .final-left {
    display: unset;
    margin-left: 53.5%;
    margin-top: -5%;
  }

  .final-right {
    display: unset;
    margin-left: 17%;
    margin-top: -5%;
  }

  .interpretation {
    display: unset;
    margin-left: 59%;
    margin-top: -3%;
    margin-bottom: 1em;
  }

 

  .triple-arrow {
    display: none; 
  }
  .triple-arrow-desktop {
    display: unset;
  }
  
  .inline {
    display: inline-block;
  }
  
}

img {
  max-width: 100%;
}

 /*
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  /* 1 */
}

/*
 * Extends the .sr-only class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.sr-only.focusable:active,
.sr-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */

.invisible {
  visibility: hidden;
  text-align: center;
}


`;

export default GlobalStyles;
