import React from "react";

export default function Footer() {
  return (
    <footer className="intro">
      <p className="margin-bottom">
        {" "}
        <strong>Contact:</strong> This website was developed by Kelly Cavaliere,
        a linguistics student finishing her bachelor’s degree at the University
        of Florida. Any questions, comments, or concerns can be directed to her
        email <a href="mailto:kcavaliere@ufl.edu">kcavaliere@ufl.edu</a>. A
        response can be expected within 2 business days.{" "}
      </p>
      <p className="margin-bottom">
        &copy; Kelly Cavaliere {new Date().getFullYear()}
      </p>
    </footer>
  );
}
